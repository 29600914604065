import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as AttributesStatisticsAPI from "@/api/analysis/attributes-statistics";
import { isSuccess } from "@/api/response";

import { AttributesStatisticsGetRequest } from "@/api/analysis/attributes-statistics/request";
import {
  AttributesStatisticsGetResponse,
  AttributesStatisticsGetResult
} from "@/api/analysis/attributes-statistics/response";
import { AnalysisGraphData } from "@/api/analysis/common/response";

const MODULE_NAME = "analysis/attributes-statistics/get";

/**
 *  属性統計API(アプリDL)API（/analysis-attributes-statistics）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: AttributesStatisticsGetRequest = {} as AttributesStatisticsGetRequest;
  getResponse: AttributesStatisticsGetResponse = {} as AttributesStatisticsGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getResult() {
    if (this.getResponse.results) {
      return this.getResponse.results;
    } else {
      return {} as AttributesStatisticsGetResult;
    }
  }

  get getTotalCount() {
    if (this.getResponse.results) {
      return this.getResponse.results.totalCount || 0;
    } else {
      return 0;
    }
  }

  get getPushData() {
    if (this.getResponse.results && this.getResponse.results["pushData"]) {
      return this.getResponse.results.pushData.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getGenderData() {
    if (this.getResponse.results && this.getResponse.results["genderData"]) {
      return this.getResponse.results.genderData.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getPeriodData() {
    if (this.getResponse.results && this.getResponse.results["periodData"]) {
      return this.getResponse.results.periodData.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile1Data() {
    if (this.getResponse.results && this.getResponse.results["profile1Data"]) {
      return this.getResponse.results.profile1Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile2Data() {
    if (this.getResponse.results && this.getResponse.results["profile2Data"]) {
      return this.getResponse.results.profile2Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile3Data() {
    if (this.getResponse.results && this.getResponse.results["profile3Data"]) {
      return this.getResponse.results.profile3Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile4Data() {
    if (this.getResponse.results && this.getResponse.results["profile4Data"]) {
      return this.getResponse.results.profile4Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile5Data() {
    if (this.getResponse.results && this.getResponse.results["profile5Data"]) {
      return this.getResponse.results.profile5Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }
  // MutationActions
  @MutationAction
  async get(getRequest: AttributesStatisticsGetRequest) {
    const getResponse = await AttributesStatisticsAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as AttributesStatisticsGetResponse
    };
  }
}

export default getModule(Get);
