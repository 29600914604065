import service from "@/api/service";
import { AttributesStatisticsGetResponse } from "@/api/analysis/attributes-statistics/response";
import { AttributesStatisticsGetRequest } from "@/api/analysis/attributes-statistics/request";

/**
 * 属性統計APIをコールします。
 *
 * @param getRequest 属性統計APIのリクエストボディ
 * @return AttributesStatisticsGetResponse
 */
export async function get(getRequest: AttributesStatisticsGetRequest) {
  const response = await service.post(
    "/analysis-attributes-statistics",
    getRequest
  );
  return response.data as AttributesStatisticsGetResponse;
}
